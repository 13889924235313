<template>
  <div class="wrapper create-page">
    <!-- Success Message -->
    <div
      class="flex flex-col w-full h-full p-4 border-2 rounded-lg shadow-md lg:w-1/2"
      v-if="!!newCampaign"
    >
      <h3 class="page-titles">
        {{ newCampaign.name }} created!
      </h3>
      <div class="my-4">
        <h5>
          Press <router-link
            class="link"
            to="/overview/0"
          >
            here
          </router-link> to distribute the tests.
        </h5>
      </div>
    </div>

    <div
      v-else
    >
      <div class="title-wrap">
        <h3 class="page-titles">
          Create campaign
        </h3>
        <p>Cognitive ability tests include four profiles. Attention, Memory, Impulse control and Creativity & Flexibility, Conceptualization and Strategy. It is always recommended to perform the Full Cognitive Profile tests to gain maximum insight.</p>
      </div>

      <div class="w-2/4 form-control name-field">
        <input
          v-model="name"
          data-theme="mytheme"
          type="text"
          placeholder="Campaign Name"
          class="input"
        >
        <span
          v-if="showNameMsg"
          class="text-lg text-red-700"
        >* Please enter a name for your campaign</span>
        <!--  -->
        <!--  -->
        <p
          v-if="testEnv"
          class="pb-0 mt-6 mb-0"
        >
          Test each test individually
        </p>
        <div
          v-if="testEnv"
          class="flex flex-col items-start"
        >
          <select
            v-if="testEnv"
            class="px-8 py-4 mt-2 mb-4 text-base border form-select select-campaign-dropdown"
            v-model="selectedTestInd"
          >
            <option value="Attention control">
              Attention control
            </option>
            <option value="Conceptualization">
              Conceptualization
            </option>
            <option value="Creativity & cognitive flexibility">
              Creativity & cognitive flexibility
            </option>
            <option value="Impulse control">
              Impulse control
            </option>
            <option value="Short term memory">
              Short term memory
            </option>
            <option value="Simple attention">
              Simple attention
            </option>
            <option value="Strategic thinking">
              Strategic thinking
            </option>
            <option value="Working memory">
              Working memory
            </option>
            <option value="Pre Ques">
              Pre Questions
            </option>
            <option value="Post Ques">
              Post Questions
            </option>
          </select>
          <!--  -->
          <!--  -->
          <button
            class="py-4 btn"
            :class="{'loading': loading}"
            @click="createIndTest"
          >
            Create individual test
          </button>
        </div>
      </div>

      <div class="ability-tests">
        <h3>
          Cognitive ability tests
        </h3>
        <p>Select the abilities you would like to test.</p>
        <span
          v-if="showPersonalityMsg"
          class="text-lg text-red-700"
        >* Personality++ can only be combined with Full Cognitive Profile</span>
        <span
          v-if="showConfigMsg"
          class="text-lg text-red-700"
        >* Please select at least one test</span>

        <div
          :class="{'full-selected': showPackagedSection}"
          class="option-box"
          v-if="testConfigurations.length > 0"
        >
          <div class="grid gap-12 sm:grid-cols-1 md:grid-cols-2">
            <div class="...">
              <test-button
                @input="formVal"
                :disabled="memory || attention || concept || impulseControl"

                :config="testConfigurations.find(cfg => cfg.name == 'Full Cognitive Profile')"
                v-model="fullCog"
              />
              <test-button
                @input="formVal"
                :disabled="fullCog"
                :config="testConfigurations.find(cfg => cfg.name == 'Gather & Process')"
                v-model="attention"
              />
              <test-button
                @input="formVal"
                :disabled="fullCog"

                :config="testConfigurations.find(cfg => cfg.name == 'Active Sketchpad')"
                v-model="memory"
              />
              <test-button
                @input="formVal"
                :disabled="fullCog"

                :config="testConfigurations.find(cfg => cfg.name == 'Monitor & Control')"
                v-model="impulseControl"
              />
              <test-button
                @input="formVal"
                :disabled="fullCog"

                :config="testConfigurations.find(cfg => cfg.name == 'Envision')"
                v-model="concept"
              />
            </div>
            <div class="flex flex-row items-center justify-center create-campaign-option-img">
              <img
                src="@/assets/capacio/camp/create-campaign-img.png"
                class="w-4/5"
              >
            </div>
          </div>

          <div class="bottom-arrow">
            <svg
              width="63"
              height="36"
              viewBox="0 0 63 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M57.5614 9.57702L31.5 35.2975L5.43864 9.57702L57.5614 9.57702Z"
                stroke="#C8C8C8"
              />
              <line
                x1="7"
                y1="9.47302"
                x2="56"
                y2="9.47302"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </div>
        <div
          v-if="showPackagedSection"
          class="package-option"
        >
          <h3>How do you want your tests to be packaged?</h3>
          <div class="flex">
            <div class="flex all-pack">
              <div class="radio-button">
                <div class="form-check">
                  <input
                    class="float-left mt-1 mr-2 align-top transition duration-200 bg-center bg-no-repeat bg-contain border border-gray-500 rounded-full appearance-none cursor-pointer radio form-check-input h-14 w-14 checked:bg-blue-600 checked:border-blue-600 focus:outline-none"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    :value="false"
                    v-model="packageSeperate"
                  >
                  <label
                    class="inline-block text-gray-800 form-check-label"
                    for="flexRadioDefault1"
                  >
                    &nbsp;
                  </label>
                </div>
              </div>
              <div>
                <h4>Package all as one test (Recommended)</h4>
                <p>The tests you have selected will be packaged as one test which will take 45 minutes for your candidate to complete.</p>
                <div class="flex justify-center package-image">
                  <img
                    src="../../../assets/capacio/create/full-pack.svg"
                    alt=""
                  >
                </div>
              </div>
            </div>

            <div class="flex seperate-pack">
              <div class="radio-button">
                <div class="form-check">
                  <input
                    class="float-left mt-1 mr-2 align-top transition duration-200 bg-center bg-no-repeat bg-contain border border-gray-500 rounded-full appearance-none cursor-pointer radio form-check-input h-14 w-14 checked:bg-blue-600 checked:border-blue-600 focus:outline-none"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    :value="true"
                    v-model="packageSeperate"
                  >
                  <label
                    class="inline-block text-gray-800 form-check-label"
                    for="flexRadioDefault1"
                  >
                    &nbsp;
                  </label>
                </div>
              </div>
              <div>
                <h4
                  class="flex flex-row "
                >
                  Package as separate tests <svg
                    class="mx-2"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    content="When taking seperate test the results may vary and we highly recommend the full test."
                    v-tippy="{arrow : true, arrowType : 'round', animation : 'fade', size: 'large'}"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 14.08L10 9.41995"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10 5.91992C9.58579 5.91992 9.25 6.25571 9.25 6.66992C9.25 7.08414 9.58579 7.41992 10 7.41992C10.4142 7.41992 10.75 7.08414 10.75 6.66992C10.75 6.25571 10.4142 5.91992 10 5.91992Z"
                      fill="black"
                    />
                    <path
                      d="M10 0.749999C4.89137 0.749999 0.750001 4.89136 0.750001 10C0.75 15.1086 4.89137 19.25 10 19.25C15.1086 19.25 19.25 15.1086 19.25 10C19.25 4.89137 15.1086 0.75 10 0.749999Z"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </h4>
                <p>The tests you have selected will have separate links and can be taken separately on preferred times, each test will take about 10-15 minutes.</p>
                <div class="flex justify-center package-image">
                  <img
                    src="../../../assets/capacio/create/seperate-packs.svg"
                    alt=""
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="questionaire"
      v-if="testConfigurations.length > 0 "
    >
      <div v-if="fullCog || (this.attention && this.memory && this.impulseControl && this.concept)">
        <h3>
          Questionnaires
        </h3>
        <p>Include a free questionnaire along with the full cognitive report. It captures several aspects of persons traits/characteristics.</p>
        <div class="option-box">
          <test-button
            :config="personalityConfig"
            v-model="personality"
          />
        </div>
      </div>

      <div class="grid justify-items-end btn-pane">
        <button
          class="btn-primary"
          :class="{'loading': loading}"
          @click="create"
        >
          Create campaign
        </button>
      </div>
    </div>
    <div>
      <input
        type="checkbox"
        id="out-of-campaigns-modal"
        class="hidden modal-toggle"
        :checked="showOutOfCampaignsModal"
      >
      <div

        class="modal"
      >
        <div
          class="modal-box"
          style="min-width: 620px;"
        >
          <h3 class="mb-4 text-base font-semibold">
            Upgrade your subscription to get access to more campaigns per month.
          </h3>
          <p class="py-4">
            You can upgrade your subscription <router-link
              :to="{name: 'Checkout'}"
              class="link"
            >
              here.
            </router-link>
          </p>
          <div class="modal-action">
            <div class="modal-action">
              <label
                for="out-of-campaigns-modal"
                class="btn-secondary"
              >Close</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TestButton from '../components/TestButton.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    TestButton
  },
  data () {
    return {
      fullCog: true,
      attention: false,
      concept: false,
      memory: false,
      impulseControl: false,
      personality: false,
      name: null,
      selectedConfigs: [],
      newCampaign: null,
      packageSeperate: false,
      loading: false,
      showNameMsg: false,
      showTermsMsg: false,
      showConfigMsg: false,
      showPersonalityMsg: false,
      showOutOfCampaignsModal: false,
      personalityConfig: {
        id: 'PERSONALITY',
        name: 'Personality++',
        description: 'A highly validated questionnaire, the outcome is a trait/characteristics profile including: the big five personality traits, empathy, social style, level of impulsivity, and emotional regulation.',
        durationMinutes: 10
      },
      testEnv: false,
      selectedTestInd: 'Attention control'
    }
  },
  computed: {
    ...mapGetters({ testConfigurations: 'CONFIGS/getTestConfigurations' }),
    showPackagedSection () {
      return this.fullCog || (this.attention && this.memory && this.impulseControl && this.concept)
    }

  },
  watch: {
    packageSeperate () {
      if (!this.packageSeperate && this.attention && this.memory && this.impulseControl && this.concept) {
        this.fullCog = true
        this.attention = false
        this.memory = false
        this.impulseControl = false
        this.concept = false
        return
      }
      if (this.packageSeperate && this.fullCog && !(this.attention && this.memory && this.impulseControl && this.concept)) {
        this.fullCog = false
        this.attention = true
        this.memory = true
        this.impulseControl = true
        this.concept = true
        this.personality = false
      }
    }
  },
  async mounted () {
    await this.$store.dispatch('CONFIGS/getTestConfigurations')
    if (process.env.VUE_APP_LOAD_ENV_TEST === 'testenv') {
      this.testEnv = true
    }
  },
  methods: {
    formVal () {
      if ((this.attention || this.memory || this.impulseControl || this.concept)) {
        this.packageSeperate = true
        this.personality = false
      }
      if (this.fullCog && !(this.attention && this.memory && this.impulseControl && this.concept)) {
        this.packageSeperate = false
      }
    },
    async create () {
      this.showTermsMsg = false
      this.showNameMsg = false
      this.showConfigMsg = false
      this.showPersonalityMsg = false
      this.showOutOfCampaignsModal = false

      // form check
      if (this.name == null) {
        this.showNameMsg = true
        return
      }
      if (!this.attention && !this.memory && !this.impulseControl && !this.concept && !this.fullCog) {
        this.showConfigMsg = true
        return
      }

      // can only select personality with full cognitive profile
      if (this.personality && (!this.fullCog && !(this.attention && this.memory && this.impulseControl && this.concept))) {
        this.showPersonalityMsg = true
        return
      }

      const selectedConfigs = []

      if (this.fullCog) {
        selectedConfigs.push(this.testConfigurations.find(cfg => cfg.name === 'Full Cognitive Profile').id)
      }
      if (this.personality) {
        selectedConfigs.push(this.personalityConfig.id)
      }
      if (this.attention) {
        selectedConfigs.push(this.testConfigurations.find(cfg => cfg.name === 'Gather & Process').id)
      }
      if (this.memory) {
        selectedConfigs.push(this.testConfigurations.find(cfg => cfg.name === 'Active Sketchpad').id)
      }
      if (this.concept) {
        selectedConfigs.push(this.testConfigurations.find(cfg => cfg.name === 'Envision').id)
      }
      if (this.impulseControl) {
        selectedConfigs.push(this.testConfigurations.find(cfg => cfg.name === 'Monitor & Control').id)
      }

      // create body for API
      const body = {
        name: this.name,
        testConfigurations: selectedConfigs
      }

      this.loading = true
      const res = await this.$api.createCampaign(body)

      if (res.error) {
        if (res.error === 'out_of_campaigns') {
          this.showOutOfCampaignsModal = true
          return
        }
      }

      this.newCampaign = res
      this.loading = false
      this.$router.push('/overview/0')
    },
    async createIndTest () {
      this.showNameMsg = false

      // form check
      if (this.name == null) {
        this.showNameMsg = true
        return
      }
      const selectedConfigs = []
      selectedConfigs.push(this.testConfigurations.find(el => el.name === this.selectedTestInd).id)

      // create body for API
      const body = {
        name: this.name,
        testConfigurations: selectedConfigs
      }
      this.loading = true
      this.newCampaign = await this.$api.createCampaign(body)
      this.loading = false
      this.$router.push('/overview/0')
    }
  }
}
</script>

<style lang="scss">@import '../create.scss';</style>
