<template>
  <div
    @click="handleSelect"
    class="row-select"
  >
    <div class="select-check">
      <input
        :disabled="disabled"
        class="checkbox"
        type="checkbox"
        :checked="value"
      >
    </div>
    <div class="descriptions">
      <div class="flex-title flex items-start">
        <h4
          class="flex flex-row"
          v-if="config.name === 'Impulse control, Creativity & Flexibility'"
        >
          {{ config.name }}
          <svg
            class="mx-2"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            content="Recommended as screening test if candidate time is limited."
            v-tippy="{arrow : true, arrowType : 'round', animation : 'fade', size: 'large'}"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 14.08L10 9.42001"
              stroke="black"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10 5.92004C9.58579 5.92004 9.25 6.25583 9.25 6.67004C9.25 7.08426 9.58579 7.42004 10 7.42004C10.4142 7.42004 10.75 7.08426 10.75 6.67004C10.75 6.25583 10.4142 5.92004 10 5.92004Z"
              fill="black"
            />
            <path
              d="M10 0.749999C4.89137 0.749999 0.750001 4.89136 0.750001 10C0.75 15.1086 4.89137 19.25 10 19.25C15.1086 19.25 19.25 15.1086 19.25 10C19.25 4.89137 15.1086 0.75 10 0.749999Z"
              stroke="black"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </h4>
        <h4 v-else>
          {{ config.name }}
        </h4>
        <div class="time flex items-center">
          <svg
            class="w-6 h-6 mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#374151"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span class="whitespace-nowrap">
            <span v-if="config.name == 'Full Cognitive Profile'">
              {{ config.durationMinutes }} min
            </span>
            <span v-else>
              10-15 min
            </span>
          </span>
        </div>
      </div>
      <p>{{ config.description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    },
    disabled: Boolean
  },
  data () {
    return {
      openCollapse: true
    }
  },
  methods: {
    handleSelect () {
      if (!this.disabled) {
        this.$emit('input', !this.value)
      }
    }
  }
}
</script>

<style scoped>
</style>
